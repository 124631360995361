import { AbstractControl, ValidationErrors } from '@angular/forms';

export function MimeTypeFormatValidator(control: AbstractControl): ValidationErrors {
  if (!control.value) {
    return null;
  }

  const value = String(control.value).toLowerCase();
  const types = value.split(/\r?\n/);
  const lineNumbers = [];

  types.forEach((type, index) => {
    if (type && !(/^[-\w.]+\/[-\w.+]+$/.test(type))) {
      lineNumbers.push(index + 1);
    }
  });

  return lineNumbers.length === 0 ? null : { 'mime-type-format': lineNumbers.join(', ') };
}
